'use client'

import dynamic from 'next/dynamic'
import { Container, Grid2 as Grid } from '@mui/material'
import Link from 'next/link'

// Components
const VideoAtom = dynamic(() => import('@/components/atoms/video'))
const BackgroundAtom = dynamic(() => import('@/components/atoms/background'))
const MainHeaderAtom = dynamic(() => import('@/components/atoms/typographies/main-header'))
const BodyTextAtom = dynamic(() => import('@/components/atoms/typographies/body'))
const QouteAtom = dynamic(() => import('@/components/atoms/typographies/qoute'))
const CoursesMolecule = dynamic(() => import('@/components/molecules/card/courses'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))

const HomeTemplates = () => {
	return (
		<Grid size={12}>
			<Grid container>
				<VideoAtom src="/videos/sample.mp4" height="100%" width="100%" title="Discover Tłı̨chǫ" description="Welcome to the Tłı̨chǫ learning. " />

				<Grid size={12} position="relative">
					<BackgroundAtom alt="background" width={1417} height={1522} image="home.png" />
				</Grid>

				<Container maxWidth="lg">
					<Grid container spacing={8}>
						<Grid size={12} mt={6}>
							<MainHeaderAtom text={'who we are?'} />
						</Grid>

						<Grid size={6}>
							<BodyTextAtom
								text={
									'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus ultrices in. Vitae elementum curabitur vitae nunc sed velit dignissim. Netus et malesuada fames ac turpis egestas maecenas pharetra. Lorem dolor sed viverra ipsum nunc aliquet. Dolor sit amet consectetur adipiscing elit. Fames ac turpis egestas sed tempus urna et pharetra. Nulla at volutpat diam ut venenatis tellus in metus.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus ultrices in. Vitae elementum curabitur vitae nunc sed velit dignissim.'
								}
							/>
						</Grid>

						<Grid size={6}>
							<BodyTextAtom
								text={
									'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus ultrices in. Vitae elementum curabitur vitae nunc sed velit dignissim. Netus et malesuada fames ac turpis egestas maecenas pharetra. Lorem dolor sed viverra ipsum nunc aliquet. Dolor sit amet consectetur adipiscing elit. Fames ac turpis egestas sed tempus urna et pharetra. Nulla at volutpat diam ut venenatis tellus in metus.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac odio tempor orci dapibus ultrices in. Vitae elementum curabitur vitae nunc sed velit dignissim.'
								}
							/>
						</Grid>

						<Grid size={12}>
							<QouteAtom text={'“Strong like two people”'} subtitle={'Chief Jimmy Bruneau'} />
						</Grid>

						<Grid size={12}>
							<MainHeaderAtom text={'Courses'} />
						</Grid>

						<Grid size={12}>
							<CoursesMolecule
								items={[
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/1.png' },
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/2.png' },
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/3.png' },
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/1.png' },
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/2.png' },
									{ title: 'Governance', hours: 100, date: 'Sept, 2025', teacherName: 'Educator Name', cardImage: '/images/cards/3.png' }
								]}
							/>
						</Grid>

						<Grid size={12} display="flex" justifyContent="center" mb={13}>
							<Link href={'/courses'}>
								<GeneralButtonAtom title={'view all'} />
							</Link>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	)
}

export default HomeTemplates
